import { Auth, API } from "aws-amplify"

export default {
  findByCode: async (locker_code) => {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()

    const init = {
      headers: {
        Authorization: token,
      },
    }
    const locker = await API.get(
      "smariDeliveryboxAPI",
      `/locker/${locker_code}`,
      init
    )
    return locker
  },
  createLockerLocation: async (code, values) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        zip: values.zip,
        prefecture: values.prefecture,
        city: values.city,
        address: values.address,
        address_no: values.address_no,
        address_ext: values.address_ext,
      },
    }
    const locker = await API.post(
      "smariDeliveryboxAPI",
      `/locker/${code}`,
      init
    )
    return locker
  },
}
