<template>
  <loading
    v-model:active="is_loading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content" v-if="is_allow_action">
    <ContentHeader title="宅配ボックス情報登録" />
    <LockerRegistFlow
      input_status="done"
      confirm_status="done"
      complete_status="active"
    />
    <div class="locker-info">
      <div class="locker-info-head">入力内容を確認してください。</div>
      <br />
      <b>住所 :</b> 〒{{ locker_location.zip }} <br />
      {{ locker_location.prefecture }}{{ locker_location.city }}{{ locker_location.address }}{{ locker_location.address_no }}{{ locker_location.address_ext }}
    </div>
    <PersonalInformationCheckBoxLockerRegist
      :errorMessage="acknowledge_error"
      v-on:check-event="checkAcknowledge"
    />
    <div class="btn-wrapper">
      <button class="button is-rounded btn-cancel" @click="back">戻る</button>
      <button
        class="button is-rounded btn-submit"
        @click="createLockerLocation"
        :disabled="!is_agree_acknowledge"
      >
        登録する
      </button>
    </div>
    <span class="help"
        >※携帯電話等で迷惑メール防止対策のためドメイン指定/制限を行なっている場合は「@smari.io」からのメールを受信できるように設定してください。</span
      >
  </div>
</template>

<script>
import LockerRepository from "../repositories/LockerRepository"
import MailService from "../services/MailService"
import { Auth } from "aws-amplify"
import Loading from "vue-loading-overlay"
import LockerRegistFlow from "../components/elements/LockerRegistFlow.vue"
import ContentHeader from "../components/ContentHeader.vue"
import PersonalInformationCheckBoxLockerRegist from "../components/PersonalInformationCheckBoxLockerRegist.vue"

export default {
  name: "LockerRegistConfirm",
  components: {
    Loading,
    LockerRegistFlow,
    ContentHeader,
    PersonalInformationCheckBoxLockerRegist,
  },
  data() {
    return {
      error_message: "",
      is_agree_acknowledge: false,
      acknowledge_error: [
        "「スマリ宅配ボックスサービスのご利用にあたって」及び「スマリ宅配ボックスサービスに係る個人情報保護基本方針」をご確認の上、ご同意いただける方は「同意する」をチェックしてください。",
      ],
      is_allow_action: false,
      is_loading: false,
    }
  },
  async created() {
    if (!this.$store.state.locker.code || !this.$store.state.locker_location.zip) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください"
      return
    }
    this.is_allow_action = true
  },
  computed: {
    locker_location() {
      return this.$store.state.locker_location
    },
    locker() {
      return this.$store.state.locker
    },
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    async createLockerLocation() {
      this.is_loading = true
      const locker_location_values = {
        ...this.locker_location,
      }
      try {
        // トランザクション更新
        await LockerRepository.createLockerLocation(
          this.locker.code,
          locker_location_values
        )
        // メール送信
        await MailService.sendLockerRegistComplete(this.locker.code, this.locker.name)

        this.$router.replace({ path: "/locker/regist-complete" })
      } catch (error) {
        this.error_message =
          "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      } finally {
        this.is_loading = false
      }
    },
    back() {
      this.$router.push({ path: "/locker/regist" })
    },
    checkAcknowledge(value) {
      this.is_agree_acknowledge = value
    },
  },
}
</script>

<style scoped>
.locker-info {
  text-align: left;
  padding-bottom: 5px;
}
.locker-info-head {
  text-align: center;
}
.btn-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 50px;
}
</style>
